import Vue from 'vue';
import Router from 'vue-router';


import LoadingComponent from "./LoadingComponent.vue";
import ErrorComponent from "./ErrorComponent.vue";

const lazyLoadView = ({ component, loading, error }) => {
  const AsyncHandler = () => ({
    component,
    loading,
    error
  });

  return () =>
    Promise.resolve({
      functional: true,
      render(h, { data, children }) {
        return h(AsyncHandler, data, children);
      }
    });
};

const MF = lazyLoadView({
  component: import("./view/pages/medical_file/file.vue"),
  loading: LoadingComponent,
  error: ErrorComponent
});

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      component: () => import('@/view/layout/Layout'),
      redirect: '/dashboard',
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue'),
        },
        {
          path: '/admin/users',
          name: 'users',
          component: () => import('@/view/pages/admin/users/ManageUsers.vue'),
        },
        {
          path: '/admin/payments',
          name: 'payments',
          component: () => import('@/view/pages/admin/payments/AllPayments.vue'),
        },
        {
          path: '/admin/admin-actions',
          name: 'actions',
          component: () => import('@/view/pages/admin/Actions/Actions.vue'),
        },
        {
          path: '/admin/app-settings',
          name: 'AppSettings',
          component: () => import('@/view/pages/admin/settings/Settings.vue'),
        },
        {
          path: '/doctor/appointment',
          name: 'doc_appointment',
          component: () => import('@/view/pages/doctor/appointments.vue'),
        },
        {
          path: '/doctor/referral',
          name: 'second_opinion',
          component: () => import('@/view/pages/doctor/referral.vue'),
        },
        {
          path: '/doctor/income',
          name: 'income',
          component: () => import('@/view/pages/doctor/income.vue'),
        },
        {
          path: '/doctor/question',
          name: 'questions',
          component: () => import('@/view/pages/doctor/questions.vue'),
        },
        {
          path: '/doctor/schedule',
          name: 'schedule',
          component: () => import('@/view/pages/doctor/schedule.vue'),
        },
        {
          path: '/medical_file/:id',
          name: 'medical_file',
          component: MF,
        },
        {
          path: '/user/appointment',
          name: 'userappointment',
          component: () => import('@/view/pages/patient/appointment.vue'),
        },
        {
          path: '/user/questions',
          name: 'userquestions',
          component: () => import('@/view/pages/patient/questions.vue'),
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/view/pages/Profile.vue'),
        },
        {
          path: '/chat',
          name: 'chat',
          component: () => import('@/view/Chat/Room.vue'),
        },
      ],
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/view/pages/error/Error.vue'),
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/view/pages/error/Error-1.vue'),
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/view/pages/error/Error-2.vue'),
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/view/pages/error/Error-3.vue'),
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/view/pages/error/Error-4.vue'),
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/view/pages/error/Error-5.vue'),
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/view/pages/error/Error-6.vue'),
        },
      ],
    },
    {
      path: '/appointment/:id',
      name: 'appointment',
      component: () => import('@/view/pages/app/appointement.vue'),
    },
    {
      path: '/privacypolicy',
      name: 'privacy_policy',
      component: () => import('@/view/pages/policy.vue'),
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/Login'),
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/view/pages/auth/Register'),
        },
        {
          name: 'registerdoc',
          path: '/doctor_register',
          component: () => import('@/view/pages/auth/Registerdoc'),
        },
        // {
        //   name: 'confirmemail',
        //   path: '/confirmemail',
        //   component: () => import('@/view/pages/auth/ConfirmEmail'),
        // },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
  mode: 'history'
});
