import ApiService from "@/core/services/api.service";
/**
 * Service to call HTTP request via Axios
 */
const FileService = {
  data: {
    currentFile: null,
    gun:null,
  },
  init() {
  },
  setGun(g)
  {
    this.data.gun = g;
    
  },
 
  async update(id, payload) {
    let res = ApiService.update("items/history_type",id, payload);
    return res;
  },
  /**
   * GET Medical File
   * @param force if true, force loading from server, if false, return local items if available, if not, load from server
   * @returns {medical_file}
   */
  async getItemByID(id,force = false)
  {
    let fetchfromserver = (this.data.currentFile == null || force);
    if(fetchfromserver)
    {
      let result = await ApiService.get(
        "items",
        `medical_file?fields=*.id&filter[owner]=${id}`
      );
      this.data.currentFile = result.data.data[0]
      return this.data.currentFile;
    }
    else{
      return this.data.currentFile;
    }
  },
  async createPatient(payload)
  {
    return ApiService.post("custom/register/register_patient",payload)
  },
  async updatePatient(id,payload)
  {
    return ApiService.update("items/medical_file",id, payload);
  },
};
export default FileService;
