const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  //console.log("token to be stored", token);
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const parseJwt = token => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
  };

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem("userInfo");
  window.localStorage.removeItem('mfdetails');
  window.localStorage.removeItem('DEPARTMENT');
  //localStorage.clear();
};

export default { getToken, saveToken, destroyToken , parseJwt };
