import ApiService from "@/core/services/api.service";
import { Base64 } from 'js-base64';
/**
 * Service to call HTTP request via Axios
 */
const HistoryService = {
  data: {
    historyTypes: null,
    gun:null,
  },
  init() {
    this.getItem();
  },
  setGun(g)
  {
    this.data.gun = g;
    this.data.gun
      .get("mf_defaults/history_type")
      .once((e) =>{
        if(e)
          this.data.historyTypes = JSON.parse(Base64.decode(e.data));
      })
  },
  updategun()
  {
    this.data.gun
      .get("mf_defaults/history_type")
      .once((e) =>{
        let d = Base64.encode(JSON.stringify(this.data.historyTypes));
        if(!e || d != e.data)
        {
          this.data.gun.get("mf_defaults/history_type").put({"data": d});
        }
      });
  },
 
  async create(payload) {
    let res = ApiService.post("items/history_type", payload);
    return res;
  },
  async update(id, payload) {
    let res = ApiService.update("items/history_type",id, payload);
    return res;
  },
  async deleteItem(id)
  {
    let res = ApiService.delete(`items/history_type/${id}`);
    return res;
  },
  async deleteTranslation(payload)
  {
    let ids = ""
    payload.forEach((item, index)=>{
      ids += item.id;
      if(index != payload.length-1)
        ids+= ','
    })
    if(ids != "")
    {
      let res = ApiService.delete(`items/history_type_translation/${ids}`);
      console.log(res);
      return res;
    }
    return false;
  },
  /**
   * Check if the history type is being used
   * @param id id of history type
   * @returns {boolean}
   */
  async isInUse(id)
  {
    let res = await ApiService.get(`items/md_history?fields=id&filter[history_type]=${id}&limit=1`);
    if(res.data.data.length == 0)
      return false;
    else
      return true;
  },
  /**
   * GET All history Types
   * @param force if true, force loading from server, if false, return local items if available, if not, load from server
   * @returns {historyTypes}
   */
  async getItem(force = false)
  {
    let fetchfromserver = (this.data.historyTypes == null || force);
    console.log("data",this.data, "is data null", this.data.historyTypes == null, "fetch from server",fetchfromserver);
    if(fetchfromserver)
    {
      console.log("loading from remote")
      let result = await ApiService.get(
        "items",
        "history_type?fields=*,translation.*,icon.*"
      );
      this.data.historyTypes = result.data.data
      this.updategun();
      return this.data.historyTypes;
    }
    else{
      console.log("returning from local", this.data.historyTypes)
      return this.data.historyTypes;
    }
  },
};
export default HistoryService;
