import ApiService from "@/core/services/api.service";
import { Base64 } from 'js-base64';
//import ls from "@/core/services/localstorage.service";
/**
 * Service to call HTTP request via Axios
 */
const PaymentService = {
  data: {
    paymentMethods: null,
    gun:null,
  },
  init() {
    this.getItem();
  },
  setGun(g)
  {
    this.data.gun = g;
    this.data.gun
      .get("settings/payment_methods")
      .once((e) =>{
        if(e)
          this.data.paymentMethods = JSON.parse(Base64.decode(e.data));
      })
  },
  updategun()
  {
    this.data.gun
      .get("settings/payment_methods")
      .once((e) =>{
        console.log("e from update",e)
        let d = Base64.encode(JSON.stringify(this.data.paymentMethods));
        console.log("e from update",d)
        if(!e || d != e.data)
        {
          this.data.gun.get("settings/payment_methods").put({"data": d});
        }
      });
  },
 
  async createItemType(payload) {
    let res = ApiService.post("items/payment_methods", payload);
    return res;
  },
  async updateItemType(id, payload) {
    let res = ApiService.update("items/payment_methods",id, payload);
    return res;
  },
  async deleteItemType(id)
  {
    let res = ApiService.delete(`items/payment_methods/${id}`);
    return res;
  },
  async deleteTypeTranslation(payload)
  {
    let ids = ""
    payload.forEach((item, index)=>{
      ids += item.id;
      if(index != payload.length-1)
        ids+= ','
    })
    if(ids != "")
    {
      let res = ApiService.delete(`items/payment_method_translation/${ids}`);
      console.log(res);
      return res;
    }
    return false;
  },
  /**
   * Check if the payment type is being used
   * @param id id of payment type
   * @returns {boolean}
   */
  async isTypeInUse(id)
  {
    let res = await ApiService.get(`items/payment?fields=id&filter[method]=${id}&limit=1`);
    if(res.data.data.length == 0)
      return false;
    else
      return true;
  },
  /**
   * GET All payment Types
   * @param force if true, force loading from server, if false, return local items if available, if not, load from server
   * @returns {paymentTypes}
   */
  async getPaymentType(force = false)
  {
    let fetchfromserver = (this.data.paymentMethods == null || force);
    console.log("data",this.data, "is data null", this.data.paymentMethods == null, "fetch from server",fetchfromserver);
    if(fetchfromserver)
    {
      console.log("loading from remote")
      let result = await ApiService.get(
        "items",
        "payment_methods?fields=*,translation.*"
      );
      this.data.paymentMethods = result.data.data
      this.updategun();
      return this.data.paymentMethods;
    }
    else{
      console.log("returning from local", this.data.paymentMethods)
      return this.data.paymentMethods;
    }
  },
  async getPateintPayments(id)
  {
    return ApiService.get(
      `custom/payment/getPatientPayment/${id}`
    );
  },
  async confirmPayment(id)
  {
    const payload = {status: 'confirmed'}
    return ApiService.update("items/payment",id, payload);
  },
  async getAll()
  {
    return ApiService.get(
      "items",
      "payment?fields=*,owner.id,owner.first_name,owner.last_name,owner.avatar.private_hash,method.id&sort=-created_on"
    );
  }
};
export default PaymentService;
