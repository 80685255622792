<template>
  <div class="hidescroll">
    <transition name="fade">
      <div
        v-show="$root.loader"
        style="
          position: absolute;
          background-color: white;
          width: 100vw;
          height: 100vh;
          z-index: 10000000;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <lottie-animation
          :animationData="
            require('@/assets/animation/lf30_editor_jzc8pwn6.json')
          "
          :loop="true"
          :autoPlay="true"
          style="height: 100px !important"
        />

        <b-progress
          :value="progress"
          height="7px"
          :style="$root.mobile ? 'width:50vw' : 'width:15vw'"
        ></b-progress>
      </div>
    </transition>
    <div v-if="isReady" style="">
      <router-view
        :dir="pointto"
        :direction="pointto"
        :class="pointto == 'rtl' ? 'rtl' : ''"
      ></router-view>
    </div>
    <div
      class="customfullview"
      style="
        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
      v-if="!$root.connectionstatus"
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <lottie-animation
          style="height: 150px"
          :animationData="require('@/assets/animation/signal.json')"
          :autoPlay="true"
          :loop="true"
        />
        <div style="margin-top: -30px; font-size: 1.2rem">
          Couldn't Connect to the Internet
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
// @import "assets/css/style.vue.rtl";
</style>

<style lang="scss">
@import "assets/css/style.vue";
@import "assets/css/custom";
</style>

<script>
//import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import LottieAnimation from "lottie-web-vue";
import { Plugins } from "@capacitor/core";
import jwtService from "./core/services/jwt.service";

//const rtl = () => import("./assets/css/style.vue.rtl.css");
//const ltr = () => import("./assets/css/style.vue.css");

//ltr();
/*import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";*/

export default {
  name: "doctory-care",
  components: {
    LottieAnimation,
  },
  data() {
    return { pointto: "ltr", isReady: false, progress: 0 };
  },
  watch: {
    progress: function (update) {
      if (update == 100) {
        setTimeout(() => {
          this.$root.loader = false;
          this.isReady = true;
        }, 1500);
      }
    },
  },
  methods: {
    async setup() {
      const { Device } = Plugins;
      const info = await Device.getInfo();
      this.$util.setDeviceInfo(info);

      console.log("in app setup");
      if (jwtService.getToken() != null) {
        await this.$user.fetchCurrentUser().then(async () => {
          console.log("node env is", process.env.NODE_ENV)
          if(process.env.NODE_ENV === "production")
          {
            await this.$user.logAccess(this.$util.getDeviceInfo());
            this.progress += 10;
          }else this.progress += 10;
            
          
          
          const user = this.$user.getCurrentUser();
          this.progress += 10;
            this.$util.startUpDS(user);
          if (user.role.id == 4) {
            //loading mf
            await this.$file.getItemByID(user.id,true).then((data) => {
              //console.log("mdeical file is", data);
              this.$root.mf = data.id;
              this.progress += 10;
            });
          } else if (user.role.id == 3) {
            console.log("doctor role is", user.role.id);
            await this.$doctor.getItemByID(user.id,true).then((data) => {
              //console.log("doctor is", data);
              this.$root.docID = data.id;
              this.progress += 10;
            });
          } else {
            this.progress += 10;
          }
        });
      } else {
        this.progress += 30;
      }

      this.setGeoData();
      this.progress += 10;
      if (!localStorage.SETTINGS) {
        this.$util.getSettings();
        this.progress += 20;
      } else {
        this.progress += 20;
      }
      console.log("*************** timezone is: ", this.$moment.tz.guess());
      localStorage.setItem("timezone", this.$moment.tz.guess());
      this.progress += 20;

      await this.$department.getDepartments().then(() => {
        //console.log("departments are",data)
        this.progress += 20;
      });
    },
    async fetchSettings() {
      await ApiService.get("items", "settings/1").then(
        ({ data }) => {
          //console.log("settings", data);
          //console.log('call duration is ', data.data['call_duration']);
          window.localStorage.setItem("SETTINGS", JSON.stringify(data.data));
          //console.log("call duration", JSON.parse(localStorage.SETTINGS).call_duration);
        },
        (error) => {
          console.error(error);
        }
      );
    },
    setGeoData() {
      if (!window.localStorage.geoData) {
        ApiService.getLocationDetails().then((data) => {
          //console.log("result of location fencing", data);
          window.localStorage.setItem("geoData", JSON.stringify(data.data));
        });
      }
    },
  },
  computed: {},
  async beforeCreate() {
    //setTimeout(() => (), 1500);

    if (localStorage.language == "ar") {
      //rtl();
      //this.$forceUpdate();
      this.pointto = "rtl";
      this.$vuetify.rtl = true;
    } else {
      //ltr();
      //this.$forceUpdate();
      this.pointto = "ltr";
      this.$vuetify.rtl = false;
    }
  },
  created() {},
  beforeMount() {
    this.setup();
    const { Network } = Plugins;
    console.log("before mount app");
    Network.getStatus().then((data) => {
      console.log("network status", data);
      this.$root.connectionstatus = data.connected;
    });
    Network.addListener("networkStatusChange", (status) => {
      this.$root.connectionstatus = status.connected;
      console.log("Network status changed", status);
    });
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    //this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    this.$nextTick(function () {
      //console.log("language is ", localStorage.language);
      if (screen.width <= 815) {
        this.$root.mobile = true;
      } else {
        this.$root.mobile = false;
      }
      //this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      if (localStorage.language) {
        this.$root.lang = localStorage.language;
      }
      if (localStorage.language == "ar") {
        //require('./assets/css/style.vue.rtl.css');
        this.pointto = "rtl";
        this.$vuetify.rtl = true;
        //this.$forceUpdate();
      } else {
        //require('./assets/css/style.vue.css');
        this.pointto = "ltr";
        this.$vuetify.rtl = false;
        //this.$forceUpdate();
      }
      try {
        if (localStorage.language != "en")
          require("moment/locale/" + localStorage.language);
      } catch (e) {
        if (e) {
          console.log("couldn't find english");
        }
      }
      /*setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);*/

      //this.$root.currentpage =
    });
  },
};
</script>
<style>
.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}
.theme--light.v-list-item:hover::before {
  opacity: 0;
}
</style>
