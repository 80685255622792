import ApiService from "@/core/services/api.service";
import ShortUniqueId from "short-unique-id";
import Vue from 'vue';
import VueCryptojs from 'vue-cryptojs'


/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const UtilityService = {
  init(ds, us) {
    this.getSettings();
    this.data.ds = ds;
    this.data.user = us;
    Vue.use(VueCryptojs);
   
  },
  EventBus : new Vue(),
  data: {
    gun:null,
    langs:null,
    settings:null,
    deviceinfo:null,
    ds:null,
    emitter:null,
    user:null,
    fx:1
  },
  setGun(g)
  {
    this.data.gun = g;
  },
  startUpDS(user)
  {
    console.log("starting up ds with user",user)
    this.data.ds.login({username: user.external_id}, async () => {
      console.log("logged into ds")
      this.openUserSocket(user.external_id)
    });
    this.data.emitter = this.data.ds.event;
  },
  openUserSocket(name)
  {
    this.data.emitter.subscribe(name,(value) => {
      console.log(`got ${value} from channel ${name}`)
      const decoded = JSON.parse(value);
      if(decoded.addChatRoom)
      {
        this.EventBus.$emit("addNewRemoteChat",decoded.addChatRoom)
      }
    });
  },
  getEmitter()
  {
    return this.data.emitter;
  },
  encrypt(payload, key)
  {
    return this.EventBus.CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      key
    ).toString()
  },
  decrypt(payload, key)
  {
    console.log("dycrpeted", this.EventBus.CryptoJS.AES.decrypt(payload,key).toString(
      this.EventBus.CryptoJS.enc.Utf8
    ))
    return JSON.parse(
      this.EventBus.CryptoJS.AES.decrypt(payload,key).toString(
        this.EventBus.CryptoJS.enc.Utf8
      ))
  },
  generateUID()
  {
    return new ShortUniqueId().stamp(32);
  },
  setDeviceInfo(info)
  {
    this.data.deviceinfo = info;
  },
  getDeviceInfo()
  {
    return this.data.deviceinfo;
  },
  getSettings()
  {
    if(this.data.settings != null)
      return this.data.settings;
    this.data.gun.get('settings/system').once((g) => {
      window.localStorage.setItem("SETTINGS", JSON.stringify(g));
      this.data.settings = g;
      return this.data.settings;
    });
  },
  getTranslation(d, lang) {
    if (d.translation && d.translation.length > 0) {
      for (var i = 0; i < d.translation.length; i++) {
        if (d.translation[i].lang == lang) {
          return d.translation[i];
        }
      }
    } else {
      return d;
    }
  },
  async getExchangeRate() {
    const res = await ApiService.convertCurrency('GBP',JSON.parse(localStorage.geoData).currencyCode);
    
      const rate = parseFloat(res.data.rates[JSON.parse(localStorage.geoData).currencyCode].rate).toFixed(2)
      this.data.fx = rate;
      return this.data.fx;
      
  },
  getLocalRate()
  {
    return this.data.fx;
  },
  getLocalCurrency() {
    return JSON.parse(localStorage.geoData).currencySymbolNative;
  },
  getLanguages()
  {
    if(this.data.langs == null)
    {
      this.data.langs = [];
      this.data.gun
      .get("settings")
      .get("langs")
      .map()
      .once((d) => {
        
        this.data.langs.push(d)
      });
      console.log("langs gun",this.data.langs)
      return this.data.langs
    }
    else{
      console.log("langs local",this.data.langs)
      return this.data.langs;
    }
    
  },
  setLanguages()
  {
    ApiService
        .get("items/languages?fields=id,name,code,flag.private_hash")
        .then((data) => {
          //console.log("languages", data.data.data);
          let lang = {};
          for (let i = 0; i < data.data.data.length; i++) {
            lang[data.data.data[i].code] = {};
            //console.log("data",data.data.data[i])
            lang[data.data.data[i].code].id = data.data.data[i].id;
            lang[data.data.data[i].code].name = data.data.data[i].name;
            lang[data.data.data[i].code].code = data.data.data[i].code;
            lang[data.data.data[i].code].icon =
              data.data.data[i].flag.private_hash;
          }
          console.log("flat langs", lang);
          this.data.gun
            .get("settings")
            .get("langs")
            .put(lang);
            
        });
  },
  getTimezone()
  {
    return localStorage.timezone;
  },
  langFilter(lang, queryText) {
    return lang.name.toLowerCase().includes(queryText.toLowerCase());
  },
  zoneFilter(z, queryText) {
    return z.zone.toLowerCase().includes(queryText.toLowerCase());
  },
  departmentFilter(department, queryText) {
    return department.name.toLowerCase().includes(queryText.toLowerCase());
  },

};
export default UtilityService;
