import ApiService from "@/core/services/api.service";
import { Base64 } from 'js-base64';
/**
 * Service to call HTTP request via Axios
 */
const DepartmentService = {
  data: {
    departments: null,
    gun:null,
  },
  init() {
    this.getDepartments();
  },
  setGun(g)
  {
    this.data.gun = g;
    this.data.gun
      .get("departments")
      .once((e) =>{
        if(e)
        {
          this.data.departments = JSON.parse(Base64.decode(e.data));
        }
      })
  },
  updategun()
  {
    this.data.gun
      .get("departments")
      .once((e) =>{
        let d = Base64.encode(JSON.stringify(this.data.departments));
        if(d != e.data)
        {
          this.data.gun.get("departments").put({"data": d});
        }
      });
  },
 
  async create(payload) {
    let res = ApiService.post("items/department", payload);
    return res;
  },
  async update(id, payload) {
    let res = ApiService.update("items/department",id, payload);
    return res;
  },
  async deleteItem(id)
  {
    let res = ApiService.delete(`items/department/${id}`);
    return res;
  },
  async deleteTranslation(payload)
  {
    let ids = ""
    payload.forEach((item, index)=>{
      ids += item.id;
      if(index != payload.length-1)
        ids+= ','
    })
    if(ids != "")
    {
      let res = ApiService.delete(`items/department_details/${ids}`);
      console.log(res);
      return res;
    }
    return false;
  },
  /**
   * GET All departments
   * @param force if true, force loading from server, if false, return local departments if available, if not, load from server
   * @returns {departments}
   */
  async getDepartments(force = false)
  {
    let fetchfromserver = (this.data.departments == null || force);
    console.log("data",this.data, "is data null", this.data.departments == null, "fetch from server",fetchfromserver);
    if(fetchfromserver)
    {
      console.log("loading from remote")
      let result = await ApiService.get(
        "items",
        "department?fields=id,name,avatar.filename_download,avatar.data,translation.*,doctors.*"
      );
      this.data.departments = result.data.data
      this.updategun();
      return this.data.departments;
    }
    else{
      console.log("returning from local", this.data.departments)
      return this.data.departments;
    }
  },
};
export default DepartmentService;
