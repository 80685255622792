import ApiService from "@/core/services/api.service";

/**
 * Service to call HTTP request via Axios
 */
const DoctorService = {
  init() {},
  data: {
    currentDoctor: null,
    errnings:null,
    gun:null,
  },
  setGun(g)
  {
    this.data.gun = g;
    
  },
  clearData()
  {
    this.data.currentDoctor = null;
    this.data.errnings = null;
  },
  async updateFirstName(id, name) {
    const payload = {
      first_name: name
    };
    return ApiService.update("users", id, payload);
  },
  /**
   * GET Doctory By ID
   * @param force if true, force loading from server, if false, return local items if available, if not, load from server
   * @returns {doctor}
   */
   async getItemByID(id,force = false)
   {
     let fetchfromserver = (this.data.currentDoctor == null || force);
     if(fetchfromserver)
     {
       let result = await ApiService.get(
         "items",
         `doctor?fields=*.id,languages_spoken.languages_id&filter[doctor_id]=${id}`
       );
       this.data.currentDoctor = result.data.data[0]
       return this.data.currentDoctor;
     }
     else{
       return this.data.currentDoctor;
     }
   },
   getCurrentDoctor()
   {
    return this.data.currentDoctor;
   },
   async getErrningsByID(id, force = false)
   {
    let fetchfromserver = (this.data.errnings == null || force);
     if(fetchfromserver)
     {
       let result = await ApiService.get(
         "items",
         `appointments?fields=*.id,owner.first_name,owner.last_name,owner.avatar.*,payment.*,payment.method.*&filter[doctor]=${id}&filter[status][neq]=deleted&sort=-created_on`
       );
       this.data.errnings = result.data.data
       return this.data.errnings;
     }
     else{
       return this.data.errnings;
     }
   },

   async createDoctor(payload)
  {
    return ApiService.post("custom/register/register_doctor",payload)
  },
  async updateDoctor(id,payload)
  {
    return ApiService.update("items/doctor",id, payload);
  },
  async updateSpokenLanguages(id,langs)
  {
    let result = await ApiService.get(
      "items",
      `doctor_languages?fields=*&filter[doctor_id]=${id}`
    );
    let ids = result.data.data.map((i) => {return i.id})
    if(ids.length > 0) await ApiService.delete(`items/doctor_languages/${ids.join()}`);

    const payload = langs.map((i) =>{
      return {doctor_id:id, languages_id:i}
    })

    return ApiService.post("items/doctor_languages",payload)
  }
};
export default DoctorService;
