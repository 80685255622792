import ApiService from "@/core/services/api.service";
//import axios from "axios";


/**
 * Service to call HTTP request via Axios
 */
const NotificationService = {
  init() {},
  async getDevicesByUser(id)
  {
    return ApiService.get(
      "items",
      `notification_center?fields=*,owner.*,owner.avatar.*&filter[owner]=${id}`
    );
  },
  async notifyByWebPush(incomingtitle, msg, recipient) {
    var payload = { messege: { title: incomingtitle, body: msg } };
    return ApiService.post(
      "custom/notification/notify/" + recipient.id,
      payload
    );
  },
  async notifyByMail(incomingtitle, msg, recipient) {
    if(recipient.email != "")
    {
      var payload = {
        to: recipient.email,
        subject: incomingtitle,
        body: msg,
        type: "html",
        use_default_email: "true",
  
      };
  
      return ApiService.postWithToken("mail", payload);
    }
    
  },
  async sendOTP(incomingtitle, msg, recipient) {
    if (recipient.email != "") {
      var payload = {
        from: {
          email: "noreply@doctory.care",
          name: "Doctory Care Team",
        },
        to: [
          {
            email: recipient.email,
            name: msg.name,
          },
        ],
        subject: incomingtitle,
        template_id: "jpzkmgqq3mg059v7",
        variables: [
          {
            email: recipient.email,
            substitutions: [
              {
                var: "company",
                value: "Doctory Care",
              },
              {
                var: "account.name",
                value: "Doctory Care",
              },
              {
                var: "support_email",
                value: "support@doctorycare.com",
              },
              {
                var: "variable",
                value: msg.code+""
              },
            ],
          },
        ],
      };

      return ApiService.post("custom/notification/mail", payload);
    }
  },
  async notifyBySMS(incomingtitle, msg, recipient) {
    var payload = { messege: incomingtitle + "<br/>" + msg };
    return ApiService.post("custom/notification/sms/" + recipient.id, payload);
  }
};
export default NotificationService;
