import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

const state = {
  questions: [],
  answeredQuestions: [],
  answeredQuestionsByMe: [],
  loadingStatus: true,
  isQuestionAnswered: false
};

const getters = {
  getQuestions: state => state.questions,
  getAnsweredQuestions: state => state.answeredQuestions,
  getAnsweredQuestionsByMe: state => state.answeredQuestionsByMe,
  getLoadingStatus: state => state.loadingStatus,
  getIsQuestionAnswered: state => state.isQuestionAnswered
};

const actions = {
  async fetchQuestions({ commit }, payload) {
    try {
      const { data } = await ApiService.get(
        "items",
        "question?fields=id,status,created_on,title,question,owner.id,owner.first_name,owner.last_name,owner.avatar.data&filter[department]=" +
          payload +
          "&filter[status]=notanswered"
      );
      commit("SET_QUESTIONS", data.data);
      // this.questions = data.data;
      // this.loadingStatus = false;
    } catch (error) {
      // this.error = error;
      console.log(error);
    }
  },
  async fetchAnsweredQuestions({ commit }, payload) {
    try {
      const { data } = await ApiService.get(
        "items",
        "question?fields=id,created_on,status,title,question,owner.id,owner.first_name,owner.last_name,owner.avatar.data,answer,rating,doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data&filter[department]=" +
          payload +
          "&filter[status]=answered"
      );
      commit("SET_ANSWERED_QUESTIONS", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchAnsweredQuestionsByMe({ commit }, payload) {
    try {
      const { data } = await ApiService.get(
        "items",
        "question?fields=id,created_on,status,title,question,owner.id,owner.first_name,owner.last_name,owner.avatar.data,answer,rating,doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data&filter[doctor]=" +
          payload +
          "&filter[status]=answered"
      );
      commit("SET_ANSWERED_QUESTIONS_BY_ME", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  updateIsQuestionAnswered({ commit }, payload) {
    commit("SET_IS_QUESTION_ANSWERED", payload);
  }
};
const mutations = {
  SET_QUESTIONS: (state, payload) => {
    state.questions = payload;
    state.loadingStatus = false;
  },
  SET_ANSWERED_QUESTIONS: (state, payload) => {
    state.answeredQuestions = payload;
    state.loadingStatus = false;
  },
  SET_ANSWERED_QUESTIONS_BY_ME: (state, payload) => {
    state.answeredQuestionsByMe = payload;
    state.loadingStatus = false;
  },
  SET_IS_QUESTION_ANSWERED: (state, payload) =>
    (state.isQuestionAnswered = payload)
};

export default {
  state,
  actions,
  mutations,
  getters
};
