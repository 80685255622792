import ApiService from "@/core/services/api.service";
/**
 * Service to call HTTP request via Axios
 */
const PrescriptionService = {
  data: {
    gun: null,
  },

  setGun(g) {
    this.data.gun = g;
  },

  async createMedicine(payload) {
    return ApiService.post("items/medicine", payload);
  },
};
export default PrescriptionService;
