import ApiService from "@/core/services/api.service";

/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const AppointmentService = {
  init() {},
  data: {
  },

  

  /**
   *
   * @param {*} id
   * @param {*} key
   * @param {*} value
   * @param {*} resource
   * @returns
   */
  async getItemByPaymentID(id) {
   return ApiService.get("items",`appointments?fields=*,doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.private_hash&filter[payment]=${id}`);
  },

};
export default AppointmentService;
