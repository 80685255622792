import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";


/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL =
    process.env.VUE_APP_api_url;
    Vue.axios.interceptors.response.use((response) => response, (error) => {
      console.log("getting error from catch all",error)
      if(error.response.status == 401)
      {
        router.push({ name: 'login' });
      }
      throw error;
    })
  },

  /**
   * Set the default HTTP request headers
   * 'Content-Type': 'multipart/form-data'
   * contentType="multipart/form-data"
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  // change

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // ////console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", token = false) {
    if (slug == "") {
      if (token) {
        return Vue.axios
          .get(`${resource}`, {
            headers: {
              Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`
            }
          })
          .catch(error => {
            throw new Error(`[KT] ApiService ${error}`);
          });
      } else {
        return Vue.axios.get(`${resource}`)
      }
    } else {
      if (token) {
        return Vue.axios
          .get(`${resource}/${slug}`, {
            headers: {
              Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`
            }
          })
          .catch(error => {
            throw new Error(`[KT] ApiService ${error}`);
          });
      } else {
        return Vue.axios.get(`${resource}/${slug}`).catch(error => {
          throw new Error(`[KT] ApiService ${error}`);
        });
      }
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  registerUser(resource, params) {
    try {
      return Vue.axios.post(`${resource}`, params, {
        headers: {
          Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`
        }
      });
    } catch (error) {
      return error;
    }
  },

  postWithToken(resource, payload) {
    try {
      console.log("req start");
      return Vue.axios.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer iJMeaShkskxr5POwYZI6erlk`
        }
      });
    } catch (error) {
      return error;
    }
  },

  // createDoctorAvails(resource, params) {
  //   try {
  //     return Vue.axios.post(`${resource}`, params, {
  //       headers: {
  //         Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`,
  //       },
  //     });
  //   } catch (error) {
  //     return error;
  //   }
  // },

  // createPatient(resource, params) {
  //   try {
  //     return Vue.axios.post(`${resource}`, params, {
  //       headers: {
  //         Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`,
  //       },
  //     });
  //   } catch (error) {
  //     return error;
  //   }
  // },

  // ///////////////////////////////

  getDepartments(resource, slug) {
    try {
      return Vue.axios.get(`${resource}/${slug}`, {
        headers: {
          Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`
        }
      });
    } catch (error) {
      return error;
    }
  },

  postMdFile(resource, params, headers) {
    return Vue.axios.post(`${resource}`, params, { headers: headers });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postFile(resource, params, index, uploadProgressCallBack) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: function(progressEvent) {
        var p = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
        if (uploadProgressCallBack) uploadProgressCallBack(index, p);
        return p;
      }
    });
  },
  simplePostFile(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.patch(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  /**
   * GET ICD code and descriptions
   * @param resource
   * @returns {*}
   */
   getICD(icd, diagnosticsname) {
    try {
      return Vue.axios.get(icd + '/' +diagnosticsname+'/' , {
        headers: {
          'x-rapidapi-key': '3fd8f5ec84msh0224d43397266dep19f940jsn0faf3e5c89ee',
          'x-rapidapi-host': 'diagnostics-code-list.p.rapidapi.com'
        },
        baseURL: 'https://diagnostics-code-list.p.rapidapi.com/'
      });
    } catch (error) {
      return error;
    }
  },
  /**
   * GET user location currency
   * @param resource
   * @returns {*}
   */
   getLocationDetails() {
    try {
      return Vue.axios.get("" , {
        params: {apikey: '873dbe322aea47f89dcf729dcc8f60e8'},
        headers: {
          'x-rapidapi-key': '3fd8f5ec84msh0224d43397266dep19f940jsn0faf3e5c89ee',
          'x-rapidapi-host': 'find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com'
        },
        baseURL: 'https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation'
      });
    } catch (error) {
      return error;
    }
  },
  /**
   * GET convert currency from to
   * @param resource
   * @returns {*}
   */
   convertCurrency(from,to) {
    try {
      return Vue.axios.get("" , {
        params: {format: 'json', from: from, to: to, amount: '1'},
        headers: {
          'x-rapidapi-key': '3fd8f5ec84msh0224d43397266dep19f940jsn0faf3e5c89ee',
          'x-rapidapi-host': 'currency-converter5.p.rapidapi.com'
        },
        baseURL: 'https://currency-converter5.p.rapidapi.com/currency/convert'
      });
    } catch (error) {
      return error;
    }
  },
};

export default ApiService;
