import Vue from "vue";
import dApp from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import NotificationService from "@/core/services/notification.service";
import LocalStorageService from "@/core/services/localstorage.service";
import DepartmentService from "@/core/services/department.service";
import VitalService from "@/core/services/mf/vital.service";
import HistoryService from "@/core/services/mf/history.service";
import PaymentService from "@/core/services/payment.service";
import UserService from "@/core/services/user.service";
import FileService from "@/core/services/mf/file.service";
import DoctorService from "@/core/services/doctor.service";
import PrescriptionService from "@/core/services/mf/prescription.service";
import AppointmentService from "@/core/services/appointment.servic";
import ChatService from "@/core/services/chat.service";

import UtilityService from "@/core/services/utility.service";

import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { DeepstreamClient } from "@deepstream/client";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


import { Plugins } from "@capacitor/core";
const { App } = Plugins;

Sentry.init({
  Vue,
  dsn: "https://fdd7a4b936fe443eba402d7ced34bd77@o922374.ingest.sentry.io/5869367",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;

import VueMoment from "vue-moment";
import moment from "moment-timezone";
import "boxicons";
import "boxicons/css/boxicons.min.css";

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import {
  uniEstate,
  uniHeartbeat,
  uniCalender,
  uniCommentAltQuestion,
  uniListUl,
  uniTrashAlt,
  uniEditAlt,
  uniCloudDownload,
  uniVideo,
  uniFileMedicalAlt,
  uniFileCheckAlt,
  uniDollarAlt,
  uniUserArrows,
  uniHospital,
  uniUsersAlt,
  uniCommentRedo,
  uniClock,
  uniArrowGrowth,
  uniSetting,
  uniUserMd,
  uniUser,
  uniKeySkeleton,
  uniSelfie,
  uniBag,
  uniUserPlus,
  uniHeartRate,
  uniTransaction,
  uniBandAid,
  uniUploadAlt,
  uniHistory,
  uniCapsule,
  uniFileCopyAlt,
  uniApple,
  uniAndroid,
  uniLaptopCloud,
  uniPoundCircle,
  uniCheckCircle,
  uniExclamationCircle,
  uniLaptop,
  uniMobileAndroid,
  uniCreditCard,
  uniUserCircle,
  uniBell,
  uniSignAlt,
  uniPauseCircle,
  uniSignOutAlt,
  uniCommentsAlt
} from "vue-unicons/dist/icons";

Unicon.add([
  uniEstate,
  uniHeartbeat,
  uniCalender,
  uniCommentAltQuestion,
  uniListUl,
  uniTrashAlt,
  uniEditAlt,
  uniCloudDownload,
  uniVideo,
  uniFileMedicalAlt,
  uniFileCheckAlt,
  uniDollarAlt,
  uniUserArrows,
  uniHospital,
  uniUsersAlt,
  uniCommentRedo,
  uniClock,
  uniArrowGrowth,
  uniSetting,
  uniUserMd,
  uniUser,
  uniKeySkeleton,
  uniSelfie,
  uniBag,
  uniUserPlus,
  uniHeartRate,
  uniTransaction,
  uniBandAid,
  uniUploadAlt,
  uniHistory,
  uniCapsule,
  uniFileCopyAlt,
  uniApple,
  uniAndroid,
  uniLaptopCloud,
  uniPoundCircle,
  uniCheckCircle,
  uniExclamationCircle,
  uniLaptop,
  uniMobileAndroid,
  uniCreditCard,
  uniUserCircle,
  uniBell,
  uniSignAlt,
  uniPauseCircle,
  uniSignOutAlt,
  uniCommentsAlt
]);
Vue.use(Unicon);


// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
//import PerfectScrollbar from 'perfect-scrollbar';
//window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import PerfectScrollbar2 from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar2);


// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@mdi/font/css/materialdesignicons.css";
if(! window.location.hostname.includes("localhost"))
  require("./registerServiceWorker"); 
//import VueGlobalVar from "vue-global-var";

//GUN
import Gun from "gun/gun";

let gunInstance = Gun([process.env.VUE_APP_gun_base], {
  localStorage: false,
});
Vue.prototype.$gun = gunInstance;
const ds = new DeepstreamClient("wss://s2.rocksoft.io:6020");
Vue.prototype.$deepstream = ds;

// API service init
ApiService.init();
DepartmentService.setGun(gunInstance);
VitalService.setGun(gunInstance);
HistoryService.setGun(gunInstance);
PaymentService.setGun(gunInstance);
UtilityService.setGun(gunInstance);

UtilityService.init(ds, UserService);
ChatService.init(gunInstance);

Vue.prototype.$api = ApiService;
Vue.prototype.$notify = NotificationService;
Vue.prototype.$ls = LocalStorageService;
Vue.prototype.$department = DepartmentService;
Vue.prototype.$vitalTypes = VitalService;
Vue.prototype.$historyTypes = HistoryService;
Vue.prototype.$payments = PaymentService;
Vue.prototype.$user = UserService;
Vue.prototype.$file = FileService;
Vue.prototype.$doctor = DoctorService;
Vue.prototype.$util = UtilityService;
Vue.prototype.$prescription = PrescriptionService;
Vue.prototype.$app = AppointmentService;
Vue.prototype.$cap = App
Vue.prototype.$chat = ChatService;


import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

//console.log("api url is",process.env.VUE_APP_api_url)

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
    
  
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
    //console.log("isauth", !store.getters.isAuthenticated, "to name",to.name , "condition is,",to.name !== 'login');
    if(to.name === 'register') next()
    else if((to.name !== 'login' && !store.getters.isAuthenticated)) next({ name: 'login' })
    else next()
  });


  // reset config to initial state

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
//Vue.use(require('vue-moment'));
Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  data: {
    //only place where data is not a function
    title: "Doctory",
    loader:true,
    ssdkurl_prod: "https://web.squarecdn.com/v1/square.js",
    ssdkurl_test: "https://sandbox.web.squarecdn.com/v1/square.js",
    stoken_prod: "sq0idp-thCboXRY8KT8a8vdHWM6pQ",
    stoken_test: "sandbox-sq0idb-kCHAoams4bSGMAbMM7jr9w",
    sloc_prod: "L0SYKZVQJFDTE",
    sloc_test: "LXHYDBCQVGD4T",
    lang: "en",
    mobile: false,
    mf: 0,
    doctormf: 0,
    viaid: 0,
    docID: 0,
    exchangerate: 1,
    connectionstatus: true,
    disablehome: true,
    chatOpen: false,
    showBar: false,
    apiURL: process.env.VUE_APP_api_url,
    assetsBaseURL: process.env.VUE_APP_assets_url,
    sysbarheight: 0,
    tabspadding: 0,
    currentpage: "dashboard",
    patient: {
      selectedDepartment: null,
    },
    admin: {
      extramargin: "90px",
      selectedRole: 1,
      openside: false,
      currentuser: null,
      selectedSetting: "system",
    },
  },
  render: (h) => h(dApp),
}).$mount("#app");
