

/**
 * LocalStorage Services
 */
const LocalStorageService = {
  init() {},

  
  write(key, value, expiry = null) {
    window.localStorage.setItem(key, this.encrypt(key, value))
    if(expiry)
    {
      window.localStorage.setItem(key+'_expiry', expiry);
    }
  },
  read(key)
  {
    if(window.localStorage[key])
    {
      if(window.localStorage[key+'_expiry'])
      {
        console.log("expired, removing from localstorage")
      }
      else
      {
        return this.decrypt(key,window.localStorage[key])
      }
    }
    else
      return null
  },
  encrypt (salt, text) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  },
  
  decrypt (salt, encoded) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  }
};

export default LocalStorageService;
